import React from "react";
import { Layout } from "../components/layout";
const style = require('../styles/404.module.scss');
export const FourOFour = () => {
    return <Layout>
        <main id="main" role="main" className={style.center}>
            <h1>Not Found</h1>
        </main>
    </Layout>;
};
export default FourOFour;
